
import LayoutContainer from '@/components/layout/Container'
import VButton from '~/components/ui/VButton.vue'
import Declension from '@/services/Declension'
import 'vue-slider-component/theme/default.css'
import { mapState } from 'vuex'

export default {
    middleware({ store, redirect }) {
        if (process.server && store.state.profile.isAuthorized) {
            if (store.getters['profile/role'] === 'investor') redirect('/profile/my-offers')
            else redirect('/profile/my-orders')
        }
    },
    head: {
        title: 'Ко-Фи — платформа коллективного финансирования для предпринимателей',
        meta: [
            { 
                hid: 'og:title',
                name: 'og:title',
                property: 'og:title',
                content: 'Ко-Фи — платформа коллективного финансирования для предпринимателей'
            },
            {
                hid: 'description',
                name: 'description',
                content: 'Быстро и легко привлекайте займы на развитие своего бизнеса. * Займы для ИП до 3 млн рублей, на срок до 1 года. Сбор средств от двух дней, выдача — мгновенно. Без залога и поручительства.',
            },
            { 
                hid: 'og:description',
                name: 'og:description',
                property: 'og:description',
                content: 'Быстро и легко привлекайте займы на развитие своего бизнеса. * Займы для ИП до 3 млн рублей, на срок до 1 года. Сбор средств от двух дней, выдача — мгновенно. Без залога и поручительства.'
            },
        ]
    },
    data() {
        return {
            sum: 100000,
            term: '12',
            percent: 21,
            comission: 0,
            comissionPercent: 0,
            sumMarks: {
                '100000': '100 000',
                '1500000': '1,5 млн',
                '3000000': '3 млн'
            },
            termMarks: {
                6: 6,
                9: 9,
                12: 12,
            },
            percentMarks: {
                '21': '21',
                '35': '35'
            },
            showedVideoPreview: true,
            declension: Declension,
        }
    },
    watch: {
        sum() {
            this.calcComissionPercent()
        },
        term() {
            this.calcComissionPercent()
        },
        percent() {
            this.calcComissionPercent()
        }
    },
    computed: {
        ...mapState('profile', [
            'currentAccount'
        ]),
        monthlyRate() {
            return Number((this.percent / 100 / 12).toFixed(5))
        },
        degree() {
            return (1 + this.monthlyRate) ** this.term
        },
        monthlyPayment() {
            return (this.sum * ((this.monthlyRate * this.degree) / (this.degree - 1))).toFixed(2)
        },
        profit() {
            return (this.sum + this.percentSum).toFixed(2)
        },
        amountReceived() {
            return this.sum ? (this.sum - this.comission).toFixed(2) : 0
        },
        percentTotal() {
            let percentSum = this.monthlyPayment * this.term - this.sum
            if (percentSum) {
                return (percentSum * 100 / this.sum).toFixed(2)
            }
            return 0
        },
        percentSum() {
            return this.monthlyPayment * this.term - this.sum
        },
        profileLink() {
            if (this.currentAccount) {
                switch(this.currentAccount.type) {
                    case 'individual':
                        return {
                            name: 'profile-individual-profile-id',
                            params: {
                                id: this.currentAccount.account_id
                            }
                        }
                    case 'entity':
                        return {
                            name: 'profile-entity-profile-id',
                            params: {
                                id: this.currentAccount.account_id
                            }
                        }
                    case 'personal':
                        return {
                            name: 'profile-personal-profile-id',
                            params: {
                                id: this.currentAccount.account_id
                            }
                        }
                }
            }
            return false
        },
    },
    mounted() {
        this.calcComissionPercent()
    },
    methods: {
        // timeout(ms) {
        //     return new Promise(resolve => {
        //         return setTimeout(resolve, ms)
        //     });
        // },
        // getCode() {
        //     return () => {
        //         return true
        //     }
        // },
        // async sendCode() {
        //     return await this.timeout(5000);
        // },
        handleRegister(role = null) {
            window.open(this.$config.AUTH, '_self');
            // if (this.currentAccount) {
            //     this.$router.push(this.profileLink)
            // } else {
            //     const url = role ? `/register?role=${role}` : '/register';
            //     window.open(url, '_self');
            // }
        },
        startVideo() {
            this.showedVideoPreview = false
            this.$refs.videoFrame.play()
            // var symbol = this.$refs.videoFrame.src.indexOf("?") > -1 ? "&" : "?";
            // this.$refs.videoFrame.src += symbol + "autoplay=1";
        },
        calcComissionPercent() {
            let comission = 1800
            if (this.sum < 100001) {
                switch (this.term) {
                    case '6':
                        this.comissionPercent = 4.5
                        comission = this.calcComission(1800, this.comissionPercent)
                        break;
                    case '12':
                        this.comissionPercent = 6
                        comission = this.calcComission(2400, this.comissionPercent)
                        break;
                    case '24':
                        this.comissionPercent = 7.5
                        comission = this.calcComission(3000, this.comissionPercent)
                        break;
                    case '36':
                        this.comissionPercent = 9
                        comission = this.calcComission(3600, this.comissionPercent)
                        break;
                }
            } else if (this.sum >= 100001 && this.sum <= 500000) {
                switch (this.term) {
                    case '6':
                        this.comissionPercent = 4
                        comission = this.calcComplexComission(4500, 100000, this.comissionPercent)
                        break;
                    case '12':
                        this.comissionPercent = 5.5
                        comission = this.calcComplexComission(6000, 100000, this.comissionPercent)
                        break;
                    case '24':
                        this.comissionPercent = 7
                        comission = this.calcComplexComission(7500, 100000, this.comissionPercent)
                        break;
                    case '36':
                        this.comissionPercent = 8
                        comission = this.calcComplexComission(9000, 100000, this.comissionPercent)
                        break;
                }
            } else if (this.sum >= 500001 && this.sum <= 1000000) {
                switch (this.term) {
                    case '6':
                        this.comissionPercent = 3.5
                        comission = this.calcComplexComission(20500, 500000, this.comissionPercent)
                        break;
                    case '12':
                        this.comissionPercent = 5
                        comission = this.calcComplexComission(28000, 500000, this.comissionPercent)
                        break;
                    case '24':
                        this.comissionPercent = 6
                        comission = this.calcComplexComission(35500, 500000, this.comissionPercent)
                        break;
                    case '36':
                        this.comissionPercent = 7
                        comission = this.calcComplexComission(41000, 500000, this.comissionPercent)
                        break;
                }
            } else if (this.sum >= 1000001 && this.sum <= 10000000) {
                switch (this.term) {
                    case '6':
                        this.comissionPercent = 3
                        comission = this.calcComplexComission(38000, 1000000, this.comissionPercent)
                        break;
                    case '12':
                        this.comissionPercent = 4
                        comission = this.calcComplexComission(53000, 1000000, this.comissionPercent)
                        break;
                    case '24':
                        this.comissionPercent = 4.5
                        comission = this.calcComplexComission(65500, 1000000, this.comissionPercent)
                        break;
                    case '36':
                        this.comissionPercent = 5
                        comission = this.calcComplexComission(76000, 1000000, this.comissionPercent)
                        break;
                }
            } else {
                switch (this.term) {
                    case 6:
                        this.comissionPercent = 2.5
                        break;
                    case 12:
                        this.comissionPercent = 3.5
                        break;
                    case 24:
                        this.comissionPercent = 4
                        break;
                    case 36:
                        this.comissionPercent = 4.5
                        break;
                }
            }
            this.comission = comission.toFixed(2)
            return this.sum ? comission.toFixed(2) : 0
        },
        calcComission(fixedSum, percent) {
            return (this.sum - (this.sum - this.sum / 100 * percent)) < fixedSum ? fixedSum : (this.sum - (this.sum - this.sum / 100 * percent))
        },
        calcComplexComission(fixedSum, min, percent) {
            let sum = this.sum - min
            return sum - (sum - sum / 100 * percent) + fixedSum
        }
    },
    components: {
        VButton,
        LayoutContainer
    }
}
